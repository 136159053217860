"use client";

import {
  SOCIAL_CHANNEL_TELEGRAM,
  SOCIAL_CHANNEL_TWITTER,
} from ".constants/links";
import {
  Box,
  Button,
  FullScreenBG,
  Subtitle,
  Text,
  Wrapper,
} from "@cloai/uikit";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useEffect } from "react";

import { useHeadersContext, useMobileContext } from "@/providers";
import Man from "@/styles/image/404-man.webp";
import IconTelegram from "@/styles/image/social-buttons/telegram.svg";
import IconTwitter from "@/styles/image/social-buttons/twitter-x-black.svg";
import styles from "@/styles/pages/404.module.scss";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const t = useTranslations("GlobalErrorPage");
  const { isMobiles } = useMobileContext();
  const { host } = useHeadersContext();
  useEffect(() => {
    // Log the error to an error reporting service
    console.log("error", error, error.stack);
  }, [error]);

  return (
    <FullScreenBG>
      <Wrapper className={styles.wrapper}>
        <Box row>
          <Box gap={isMobiles ? 24 : 40} fit>
            <Subtitle
              text={t.rich("text1", {
                orange: (chunk) => <span data-color="orange">{chunk}</span>,
              })}
              center
              className={styles.text}
            />

            {isMobiles ? (
              <Box gap={20} row center>
                <Box gap={0}>
                  <Text
                    text="5"
                    color="orange"
                    center
                    className={clsx(styles.big_numbers, styles.text)}
                  />
                  <Text
                    text="0"
                    color="orange"
                    center
                    className={clsx(styles.big_numbers, styles.text)}
                  />
                  <Text
                    text="0"
                    color="orange"
                    center
                    className={clsx(styles.big_numbers, styles.text)}
                  />
                </Box>
                <Image
                  priority
                  src={Man}
                  alt={"guy"}
                  width={204}
                  height={300}
                  className={styles.img_man}
                />
              </Box>
            ) : (
              <Text
                text="500"
                color="orange"
                center
                className={clsx(styles.big_numbers, styles.text)}
              />
            )}

            <Subtitle
              text={t.rich("text2", {
                orange: (chunk) => <span data-color="orange">{chunk}</span>,
              })}
              center
              className={styles.text}
            />

            <Button
              iconLeft="autorenew"
              text={t("buttonText")}
              size={isMobiles ? "normal" : "large"}
              wide
              centerText
              onClick={reset}
            />

            <Box gap={12} row>
              <Button
                href={SOCIAL_CHANNEL_TELEGRAM(host)}
                target="_blank"
                text="Telegram"
                iconLeft={IconTelegram}
                color="telegram"
                size={isMobiles ? "normal" : "large"}
                wide
                authType
              />
              {host.includes("okbra") ? null : (
                <Button
                  href={SOCIAL_CHANNEL_TWITTER(host)}
                  target="_blank"
                  text="Twitter"
                  iconLeft={IconTwitter}
                  color="twitter"
                  size={isMobiles ? "normal" : "large"}
                  wide
                  authType
                />
              )}
            </Box>
          </Box>

          {!isMobiles && (
            <Image
              priority
              src={Man}
              alt={"guy"}
              width={389}
              height={570}
              className={styles.img_man}
            />
          )}
        </Box>
      </Wrapper>
    </FullScreenBG>
  );
}
