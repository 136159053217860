/** Путь для главной страницы. */
export const PATH_HOME = "/";

/** Путь для страницы "О нас". */
export const PATH_ABOUT_US = "/about_us";

/** Путь для страницы Android. */
export const PATH_ANDROID = "/android";

/** Путь для аутентификации. */
export const PATH_AUTH = "/auth/signin";

/** Путь для запроса на проверку аутентификации. */
export const PATH_AUTH_VERIFY_REQUEST = "/auth/verify_request";

/** Путь для страницы Блога. */
export const PATH_BLOG = "/blog";

/** Путь для страницы покупок. */
export const PATH_BUY = "/buy";

/** Путь для страницы "Свяжитесь с нами". */
export const PATH_CONTACT_US = "/contact_us";

/** Путь для API "Свяжитесь с нами". */
export const PATH_CONTACT_US_API = "/contact_us_api";

/** Путь для страницы разработчика. */
export const PATH_DEVELOPER = "/developer";

/** Путь для страницы баланса разработчика. */
export const PATH_DEVELOPER_BALANCE = "/developer/balance";

/** Путь для профиля разработчика. */
export const PATH_DEVELOPER_PROFILE = "/developer/profile";

/** Путь для страницы заработка. */
export const PATH_EARN = "/earn";

/** Путь для страницы заработка - квесты. */
export const PATH_EARN_TASKS = "/earn/tasks";

/** Путь для страницы FAQ. */
export const PATH_FAQ = "/faq";

/** Путь для страницы бесплатного контента. */
export const PATH_FREE = "/free";

/** Путь для страницы iOS. */
export const PATH_IOS = "/ios";

/** Путь для заработка. */
export const PATH_MAKE_MONEY = "/make_money";

/** Путь для кабинета заработка. */
export const PATH_MAKE_MONEY_CABINET = "/make_money/cabinet";

/** Путь для вывода из кабинета заработка. */
export const PATH_MAKE_MONEY_CABINET_WITHDRAW = "/make_money/cabinet/withdraw";

/** Путь для истории вывода из кабинета заработка. */
export const PATH_MAKE_MONEY_CABINET_WITHDRAW_HISTORY =
  "/make_money/cabinet/withdraw/history";

/** Путь для посадочной с мужиками. */
export const PATH_MEN = "/men";

/** Путь для модалки с пакетами коинов. */
export const PATH_NOT_ENOUGH_COINS = "/not-enough-coins";

/** Путь для страницы прессы. */
export const PATH_PRESS = "/press";

/** Путь для страницы политики конфиденциальности. */
export const PATH_PRIVACY = "/privacy";

/** Путь для страницы политики конфиденциальности. */
export const PATH_PROCESSING_PRIVACY = "/processing_policy";

/** Путь для страницы возврата. */
export const PATH_REFUND = "/refund";

/** Путь для страницы RTA-рейтинга. */
export const PATH_RTA = "/rta";

/** Путь для посадочной страницы с SweetieFox. */
export const PATH_SWEETIE_FOX = "/sweetie_fox";

/** Путь для страницы подписки. */
export const PATH_PAY = "/pay";

/** Путь для страницы предложения подписки. */
export const PATH_PAY_OFFER = "/pay/offer";

/** Путь для страницы условий использования. */
export const PATH_TERMS = "/terms";

/** Путь для Telegram-бота. */
export const PATH_TG_BOT = "/tg_bot";

/** Путь для страницы вакансий. */
export const PATH_VACANCIES = "/vacancies";

/** Путь для страницы политики использования. */
export const PATH_USE_POLICY = "/use_policy";

/** Путь для страницы политики поддержки клиентов. */
export const PATH_COMPLAINT_POLICY = "/complaint-policy";

/** Имя Telegram-бота. */
export const TG_BOT_NAME = "Cloth0ff126_bot";

/** Путь для посадочной под бренд-запросы. */
export const PATH_PRELAND_UDRESS_AI = "/undress-ai";
export const PATH_PRELAND_DEEPNUDE = "/deepnude";
export const PATH_PRELAND_NUDIFY = "/nudify";
export const PATH_PRELAND_MUKE_AI = "/muke-ai";
export const PATH_PRELAND_NUDE_AI = "/nude-ai";
export const PATH_PRELAND_AI_CLOTHES_REMOVER = "/ai-clothes-remover";
export const PATH_PRELAND_DEEPSUKEBE = "/deepsukebe";
export const PATH_PRELAND_UNDRESSHER = "/undressher";
export const PATH_PRELAND_DEEPNUDE_AI = "/deepnude-ai";
export const PATH_PRELAND_NUDIFY_AI = "/nudify-ai";
export const PATH_PRELAND_CLOTHOFF_IO = "/clothoff-io";
export const PATH_PRELAND_DRAWNUDES = "/drawnudes";

export const PATH_QR_GET_APP = "/qr-get-app";

/** Ссылка на Telegram-канал. */
export const SOCIAL_CHANNEL_TELEGRAM = (host: string) =>
  host.includes("nudiva")
    ? "https://t.me/nudivaio"
    : host.includes("deepnudeai.net")
      ? "https://t.me/deep_nude_ai"
      : host.includes("okbra")
        ? "https://t.me/okbraabot"
        : "https://t.me/ClothOff16";

/** Ссылка на Telegram-канал для подписки на задания. */
export const SOCIAL_CHANNEL_TELEGRAM_SUBSCRIBE_TASK = (host: string) =>
  host.includes("nudiva")
    ? "https://t.me/nudivaio"
    : host.includes("deepnudeai.net")
      ? "https://t.me/deep_nude_ai"
      : "https://t.me/ClothOff16";

/** Ссылка на Telegram-канал для API-продавцов. */
export const SOCIAL_CHANNEL_TELEGRAM_API_SELLER = (host: string) =>
  host.includes("nudiva")
    ? "https://t.me/nudivaio"
    : host.includes("deepnudeai.net")
      ? "https://t.me/deep_nude_ai"
      : host.includes("deepnudeai.io")
        ? "https://t.me/deep_nude_ai"
        : "https://t.me/vipsupCloth";

/** Ссылка на Twitter-канал. */
export const SOCIAL_CHANNEL_TWITTER = (host: string) =>
  host.includes("nudiva")
    ? "https://x.com/nudivaio?s=21&t=pi4BjjDrckDLWr7qREyPAw"
    : host.includes("deepnudeai.net")
      ? "https://x.com/Deepnude_ai"
      : host.includes("deepnudeai.io")
        ? "https://x.com/Deepnude_ai"
        : host.includes("nudecity")
          ? "https://x.com/NCity93876"
          : "https://twitter.com/TheClothOff";

/** Ссылка на Twitter-канал для подписки на задания. */
export const SOCIAL_CHANNEL_TWITTER_SUBSCRIBE_TASK_POST =
  "https://x.com/clothoff2/status/1770139332113846374?s=46&t=pi4BjjDrckDLWr7qREyPAw";

/** Ссылка на Scamadviser для отзыва на задания. */
export const SOCIAL_CHANNEL_SCAMADVISER_SUBSCRIBE_TASK =
  "https://www.scamadviser.com/check-website/clothoff.io";

/** Ссылка на SiteJabber для отзыва на задания. */
export const SOCIAL_CHANNEL_SITEJABBER_SUBSCRIBE_TASK =
  "https://www.sitejabber.com/reviews/clothoff.io";

/** Ссылка на Mywot для отзыва на задания. */
export const SOCIAL_CHANNEL_MYWOT_SUBSCRIBE_TASK =
  "https://www.mywot.com/scorecard/clothoff.io";

/** Ссылка на форму для становления дилером. */
export const FORM_BECOME_DEALER = "https://forms.gle/Z7a5sKhudQbWAuvY6";

/** Ссылка на документацию по API. */
export const API_DOCUMENTATION_README = "https://clothoff.readme.io";

/** Ссылка на asulabel.com */
export const PATH_ASULABEL = "https://asulabel.com";

export const PATHS = {
  PATH_HOME,
  PATH_ANDROID,
  PATH_AUTH,
  PATH_AUTH_VERIFY_REQUEST,
  PATH_BLOG,
  PATH_BUY,
  PATH_CONTACT_US,
  PATH_CONTACT_US_API,
  PATH_DEVELOPER,
  PATH_DEVELOPER_BALANCE,
  PATH_DEVELOPER_PROFILE,
  PATH_EARN,
  PATH_FREE,
  PATH_IOS,
  PATH_MAKE_MONEY,
  PATH_MAKE_MONEY_CABINET,
  PATH_MAKE_MONEY_CABINET_WITHDRAW,
  PATH_MAKE_MONEY_CABINET_WITHDRAW_HISTORY,
  PATH_PRESS,
  PATH_PRIVACY,
  PATH_REFUND,
  PATH_SWEETIE_FOX,
  PATH_PAY,
  PATH_PAY_OFFER,
  PATH_TERMS,
  PATH_TG_BOT,
  PATH_USE_POLICY,
  PATH_PRELAND_UDRESS_AI,
  PATH_PRELAND_DEEPNUDE,
  PATH_PRELAND_NUDIFY,
  PATH_PRELAND_MUKE_AI,
  PATH_PRELAND_NUDE_AI,
  PATH_PRELAND_AI_CLOTHES_REMOVER,
  PATH_PRELAND_DEEPSUKEBE,
  PATH_COMPLAINT_POLICY,
};
